import { Pipe, PipeTransform } from '@angular/core'

@Pipe({
  name: 'nl2br',
  standalone: true,
})
export class Nl2brPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value) return ''
    if (args === 'multiple') {
      return value.replace(/\n/g, '<br>')
    } else {
      value = value.replace(/\.\n\./g, '')
      return value.replace(/\n+/g, '<br>')
    }
  }
}
